<template>
    <div id="appCapsule">
        <div class="section wallet-card-section">
            <div class="card">
                <div class="col-12 text-primary py-2">
                    Contacts
                </div>
                <div class="table-responsive" style="padding-bottom: 100px">
                    <table class="table">
                        <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Status</th>
                            <th scope="col">Created</th>
                            <th scope="col" class="text-end">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-if="contacts.length > 0">
                            <tr v-for="(contact, idx) in contacts" :key="idx">
                                <th scope="row">{{idx+1}}</th>
                                <td>{{contact.data.name}}</td>
                                <td>
                                    {{contact.data.email}}
                                </td>
                                <td>
                                    <span class="badge badge-success" v-if="contact.data.is_read">
                                        read
                                    </span>
                                    <span class="badge badge-warning" v-else>
                                        unread
                                    </span>
                                </td>
                                <td>{{getReadableDatetime(contact.data.createdAt)}}</td>
                                <td>
                                    <div class="dropdown">
                                        <a class="text-primary dropdown-toggle" href="javascript:void(0)" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            <ion-icon name="list-outline" class="fw-20"></ion-icon>
                                        </a>
                                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li>
                                                <a class="dropdown-item" href="javascript:void(0)" @click="active_contact=contact" data-bs-toggle="modal" data-bs-target="#viewContact">
                                                    <ion-icon class="text-primary fw-20" name="eye-outline"></ion-icon> view
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                        </template>
                        <template v-else>
                            <tr>
                                <td class="text-center text-primary" colspan="6">
                                    <h5>Empty contacts history</h5>
                                </td>
                            </tr>
                        </template>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>

        <view-contact :contact="active_contact"/>
    </div>
</template>

<script>
import methodsMixin from "../../utils/methodsMixin";
import {mapGetters} from "vuex";
import viewContact from '../../components/admin/modals/viewContact';

export default {
    name: 'Contacts',
    data(){
        return {
            active_contact: {id: '', data: {}}
        }
    },
    computed: {
      ...mapGetters('contact', {
          contacts: 'getContacts'
      })
    },
    mixins: [methodsMixin],
    components: {
        viewContact
    },
    mounted() {
        this.$store.dispatch('contact/fetchContacts')
    }
};
</script>

<style scoped>

</style>