<template>
    <div class="modal fade modalbox" id="viewContact" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        {{contact.data.email}}
                    </h5>
                    <a href="javascript:void(0);" data-bs-dismiss="modal">Close</a>
                </div>
                <div class="modal-body">
                    <p class="text text-primary">
                        Name
                        <span class="float-end">{{contact.data.name}}</span>
                    </p>
                    <p class="text text-dark">
                        Email
                        <span class="float-end">{{contact.data.email}}</span>
                    </p>
                    <p class="text text-dark">
                        Message
                    </p>
                    <p class="text text-muted">
                        {{contact.data.message}}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'viewContact',
    props: ['contact'],
    watch: {
        contact(newVal){
            if(!newVal.data.is_read){
                this.$store.dispatch('contact/readMessage', newVal.id)
            }
        }
    }
};
</script>

<style scoped>

</style>